const $menus = document.querySelectorAll('.act-top-menu>.wp-block-buttons>.wp-block-button>a');
console.log('8');

if($menus){
    $menus.forEach((el)=>{
        let submenu = el.parentElement.querySelector('ul');
        if(submenu){
            el.addEventListener('click',(e)=>{
                e.preventDefault();
                triggerMenu(el);
                jsLock = false;
                return false;
            })
            el.addEventListener('keydown',(e)=>{
                //console.log(e.key);
                //e.preventDefault();
                if(e.key == 'Enter'){
                    triggerMenu(el);
                }
                //return false;
            })
        }
    })
    document.addEventListener('keydown',(e)=>{
        //e.preventDefault();
        if(e.key == 'Escape'){
            closeAnyMenu();
        }
        //return false;
    })
    document.addEventListener('keyup',(e)=>{
        jsLock = false;
    })
}

window.addEventListener('click', function(e){   
    const $top_menu = document.querySelector('.act-top-menu');
    if ($top_menu && $top_menu.contains(e.target)){
      // Clicked in box
    } else{
      // Clicked outside the box
      closeAnyMenu();
    }
});

let jsLock = false;
function triggerMenu(el = false){
    if(el && !jsLock){
        //Prevent double trigger
        jsLock = true;
        el.closest('li').classList.toggle('is-active')
    }
}

function closeAnyMenu(){
    $menus.forEach((el)=>{
        el.closest('li').classList.remove('is-active');
    })
}